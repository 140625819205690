/**
 * Compute element's effective z-index
 */
export function computeZIndex (el: HTMLElement | null): number {
  while (el) {
    const zIndex = Number(window.getComputedStyle(el).getPropertyValue('z-index')) || 0

    if (zIndex) {
      return zIndex
    }
    el = el.parentElement
  }

  return 0
}

/**
 * Find a parent by class
 */
export function getParentByClass (el: HTMLElement | null, className: string): HTMLElement | null {
  while (el) {
    if (el.classList.contains(className)) return el
    el = el.parentElement
  }

  return null
}

/**
 * Checks if element 1 is visually above of the element 2
 */
export function isAbove (elem1: HTMLElement, elem2: HTMLElement): boolean|undefined {
  const getAncestors = (elem: HTMLElement) => {
    const ancestors = [elem]

    // walk all parents and get ancestor list
    while (elem.parentElement) {
      elem = elem.parentElement
      ancestors.push(elem)
    }

    // flip list so it starts with root
    return ancestors.reverse()
  }

  const declaredBefore = (elem1: HTMLElement, elem2: HTMLElement) => {
    const a1 = getAncestors(elem1)
    const a2 = getAncestors(elem2)

    // check path, starting from root
    for (let i = 0; i < a1.length; i++) {
      // at first divergent path
      if (a1[i] !== a2[i]) {
        // get children of common ancestor
        const testNodes = a1[i - 1].childNodes

        // check them for first disparate ancestor
        for (let j = 0; j < testNodes.length; j++) {
          if (testNodes[j] === a1[i]) return true     // elem1 is first
          if (testNodes[j] === a2[i]) return false    // elem2 is first
        }
      }
    }

    // could not determine what was first
    return undefined
  }

  // rudimentary z-index check for elems sharing a parent
  if (elem1.parentNode === elem2.parentNode) {
    const z1 = elem1.style.zIndex
    const z2 = elem2.style.zIndex

    // if both have z-index, test that
    if (z1 !== undefined && z2 !== undefined) {
      return z1 > z2
    }
  }

  // if 2 is declared before 1, 1 is on top
  return declaredBefore(elem2, elem1)
}
