import React from 'react'

type GapProps = {
  size?: number
}

export const Gap = ({ size }: GapProps): JSX.Element => <div style={{margin: 'auto', minHeight: size ? `${size}rem` : 0}} />

Gap.defaultProps = {
  size: 1,
}
