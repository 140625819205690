import React, { lazy, Suspense } from 'react'

const AdminPage = lazy(() => import('./index'))

export const AdminPageLoader = () => {
  return (
    <Suspense fallback={<div />}>
      <AdminPage />
    </Suspense>
  )
}
