import React, { ForwardedRef, forwardRef, PropsWithoutRef, ReactNode, useCallback } from 'react'
import './index.css'

type InputProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> & {
  input?: string
  label?: string
  onChange: (value: string) => void
  className?: string
  skin?: 'error'
  rightBlock?: ReactNode
}

export const Input = forwardRef<HTMLInputElement, InputProps>((props: PropsWithoutRef<InputProps>, outerReference: ForwardedRef<HTMLInputElement>) => {
  const { label, value, onChange, className, skin, rightBlock, ...rest } = props

  const _onChange = useCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
    onChange(ev.target.value)
  }, [onChange])

  return (
    <label className={['input', className, skin ? `input--${skin}` : ''].filter(Boolean).join(' ')}>
      {
        label ? <span>{ label }</span> : null
      }
      <div className="input__row" ref={outerReference}>
        <input
          type="text"
          placeholder="..."
          { ...rest }
          value={value ?? ''}
          onChange={_onChange}
        />
        {
          rightBlock ?
            <div onClick={props.onClick} className="input__right">{ rightBlock }</div>
            : null
        }
      </div>

    </label>
  )
})
