import { initReactI18next } from 'react-i18next'
import i18n from 'i18next'

// @ts-ignore
import en from './en.json'
// @ts-ignore
import de from './de.json'
// @ts-ignore
import fr from './fr.json'

i18n.use(initReactI18next).init({
  resources: {
    en, de, fr,
  },
  lng: 'en',
  fallbackLng: 'en',
  debug: false,
})

export default i18n
