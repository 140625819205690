import { Button, ButtonProps } from '@components/UI/Button'
import { Modal, ModalBottom } from '@components/UI/Modal'
import { createRoot, Root } from 'react-dom/client'
import { getCopies } from '@services/system'
import { Input } from '@components/UI/Input'
import React, { useState } from 'react'

type ButtonConfig = {
  label: string
  code: number
  skin?: string
  props?: Partial<ButtonProps>
  keyCode?: string
}

export type MboxProps = {
  title?: string
  content: React.ReactNode
  buttons?: ButtonConfig[]
  style?: React.CSSProperties
}

/**
 * A generalized MessageBox call.
 */
export async function mbox (props: MboxProps): Promise<number> {
  const { title, content, buttons, style } = props
  let localRoot: Root
  const keyPressEventListeners = [] as any[]

  return new Promise(resolve => {
    const
      wrapper = document.createElement('div'),
      mboxContainer = document.getElementById('root')

    wrapper.id = `mbox-${Math.random()}`

    if (!mboxContainer) {
      throw new Error(`Cannot find a DOM container element with ID = "root"`)
    }

    mboxContainer.appendChild(wrapper)

    const onChoiceMade = (code: number) => () => {
      // don't make the serious people wait
      resolve(code)

      // ease the blink in some browsers
      wrapper.style.display = 'none'
      localRoot.unmount()
      keyPressEventListeners.forEach(l => window.removeEventListener('keypress', l))
    }

    const defaultButtons = [
      // OK ('positive') has code 1 to be able to assign 0 to cancel and do things like
      // if (await confirm('Are you sure?')) { ... }
      { label: getCopies('buttons.ok'), code: 1 }
    ] as ButtonConfig[]

    const body =
      <Modal
        header={title}
        isMovable={true}
        isCentered={true}
        width="auto"
        height="auto"
        style={style || {minWidth: '300px', minHeight: '150px'}}
      >
        { content }
        <ModalBottom>
          {
            (buttons || defaultButtons).map((el, i) => {
              if (el.keyCode) {
                const keyPressEventListener = (ev: KeyboardEvent) => {
                  if (ev.code === el.keyCode) {
                    onChoiceMade(el.code)()
                  }
                }
                window.addEventListener('keydown', keyPressEventListener)
                keyPressEventListeners.push(keyPressEventListener)
              }

              return (
                <Button
                  key={i}
                  {...(props ?? {})}
                  onClick={onChoiceMade(el.code)}
                  // @ts-ignore
                  skin={el.skin || 'primary'}
                >
                  { el.label }
                </Button>
              )
            })
          }
        </ModalBottom>
      </Modal>

    localRoot = createRoot(wrapper)
    localRoot.render(body)
  })
}

/**
 * A hot replacement for native JS alert()
 */
export async function alert (message: string | React.ReactNode): Promise<number> {
  return mbox({title: getCopies('modals.alertTitle'), content: message})
}

/**
 * A hot replacement for native JS confirm()
 */
export async function confirm (message: string): Promise<number> {
  const buttonCopies = getCopies<{yes: string, no: string}>('buttons')

  return mbox({
    title: getCopies('modals.confirmTitle'),
    content: message,
    buttons: [
      { label: buttonCopies.yes, code: 1 },
      { label: buttonCopies.no, code: 0, skin: 'secondary' },
    ],
  })
}

/**
 * A hot replacement for native JS prompt()
 * The unhandy return polymorphism is here to comply with the native signature
 */
export async function prompt (title: string, message?: string): Promise<string|null> {
  const state = { value: message ?? '' }

  const Content = () => {
    const [value, setValue] = useState(message || '')

    return (
      <div className="lui lui-prompt__content">
        <Input
          autoFocus={true}
          value={value}
          onChange={(v) => {
            state.value = v
            setValue(v)
          }}
        />
      </div>
    )
  }

  const buttonCopies = getCopies<{ok: string, cancel: string}>('buttons')

  const result = await mbox({
    title,
    content: <Content/>,
    buttons: [
      { label: buttonCopies.ok, code: 1, keyCode: 'Enter' },
      { label: buttonCopies.cancel, code: 0, skin: 'secondary', keyCode: 'Escape' },
    ],
  })

  return result ? state.value : null
}
