import React from 'react'
import './index.css'

export interface ModalBottomProps {
  children: React.ReactNode
}

export const ModalBottom = (props: ModalBottomProps): JSX.Element => {
  return (
    <div className="modal-bottom">
      { props.children }
    </div>
  )
}
