import { attractivePrice, capitalize, capitalizeAll, currencyIcon, formatPrice, nicerName, safeJSONParse } from '@utils/text'
import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import { AircraftState, File, RichAircraftAd, VATObject } from '@serverTypes'
import { getItemById, runItemExternalChecks } from '@services/item'
import { useCopies, useLocationLanguagePrefix } from '@utils/hooks'
import { getNiceState, listEquipment } from '@utils/aircraft'
import { TopNavigator } from '@components/TopNavigator'
import { FeedbackForm } from '@pages/item/feedbackForm'
import { redirect, useParams } from 'react-router-dom'
import { lockScreen } from '@components/BusyScreen'
import { SafeImage } from '@components/SafeImage'
import { dialogify } from '@components/UI/Modal'
import { alert } from '@components/MessageBox'
import { VatForm } from '@pages/item/vatForm'
import { getConfig } from '@services/config'
import { JSONLD } from '@components/JSONLD'
import { Footer } from '@components/Footer'
import { ItemCheckResult } from '@types'
import { apiCall } from '@services/api'
import './index.css'

const getFileUrl = (file: File): string => file && file.uuid ? `${getConfig().urlPrefix}/data/images/${file.uuid}.webp` : ''

const infoModule = (title: string, value: ReactNode) => {
  return (
    value ?
      <>
        <h2>{ title }</h2>
        <div>{ value }</div>
      </>
      : null
  )
}

const airfieldRegex = /([A-Z]{2}-\d{4}|[A-Z]{4})/g

export const ItemPage = () => {
  const { itemId } = useParams()
  const [item, setItem] = useState<RichAircraftAd | undefined>(window.ssrData.itemGetById)
  const [itemCheckResult, setItemCheckResult] = useState<ItemCheckResult | undefined>(undefined)
  const [selectedImageIndex, setSelectedImageIndex] = useState(0)
  const [imageIsScalable, setImageIsScalable] = useState(false)
  const refImage = useRef<HTMLImageElement>(null)
  const languagePrefix = useLocationLanguagePrefix()

  const copies = useCopies<{
    description: string, engine: string, equipment: string, accidents: string,
    tailNumber: string, location: string, contact: string, condition: string,
    wrongEmail: string, plusVat: string, reportAd: string, typeInfo: string
  }>('pages.item')

  useEffect(() => {
    getItemById(Number(itemId)).then(setItem)
    runItemExternalChecks(Number(itemId)).then(setItemCheckResult)
  }, [itemId])

  const popThisImage = useCallback((id: number) => {
    item?.files && setSelectedImageIndex(item.files.findIndex(file => file.id === id))
  }, [item])

  useEffect(() => {
    setTimeout(() => {
      const im = refImage.current
      if (im) {
        setImageIsScalable(im.naturalWidth > im.width || im.naturalHeight > im.height)
      }
    }, 100)
  }, [refImage, selectedImageIndex])

  const onReportClick = useCallback(async (ev: React.MouseEvent) => {
    ev.preventDefault()

    const formData = await dialogify(FeedbackForm) as {from: string, feedback: string} | false
    if (!formData) return

    const result = (await apiCall<{status: number}>('guest', 'reportAd', {...formData, adId: item?.id}))?.data
    if (!result) return

    if (result.status) {
      void alert(copies.wrongEmail)
    }
  }, [item, copies.wrongEmail])

  const vatId = item?.flags?.withVat ? item.user.vat : undefined

  const onVatClick = useCallback(async (ev: React.MouseEvent) => {
    ev.preventDefault()
    if (!vatId) {
      return
    }
    lockScreen(true)
    const report = await apiCall<VATObject>('guest', 'checkVat', {vatId})
    lockScreen(false)
    // @ts-ignore
    void dialogify(VatForm, {report, vatId})
  }, [vatId])

  if (!item) {
    return null
  }

  const { aircraft, files, description, descriptionNice, price, currency, user } = item
  const mainImage = files[selectedImageIndex]
  const mainImageUrl = getFileUrl(mainImage)
  const fullName = nicerName(aircraft.vendor.name === aircraft.type.name ? aircraft.type.name : `${aircraft.vendor.name} ${aircraft.type.name}`)
  document.title = fullName

  if (!item.files.length) {
    redirect(languagePrefix)
    return null
  }

  const email = user.email
  const phone = user.phone?.replace('+00', '+')
  const equipmentList = listEquipment(aircraft.equipment)
  const builtYear = aircraft.builtAt ? String((new Date(aircraft.builtAt)).getFullYear()) : ''
  const safeAirfield = (aircraft.airfield || '')

  const niceCopies = safeJSONParse(descriptionNice || '')
  const descriptionCopy = niceCopies[languagePrefix.replace('/', '')] || niceCopies['en'] || niceCopies || description

  return (
    <div className="page-item">
      <JSONLD
        id={item.id}
        price={price}
        currency={currency}
        description={descriptionCopy}
        images={files.map(getFileUrl)}
        userName={user.name ?? 'Happy User'}
        name={nicerName(aircraft.type.name)}
        brandName={nicerName(aircraft.vendor.name)}
        isNew={aircraft.state === AircraftState.New}
      />
      <TopNavigator title={fullName} />
      <div className="page-item__content">
        <div className="page-item__images">
          <SafeImage
            ref={refImage}
            alt={fullName}
            src={mainImageUrl}
            onClick={() => imageIsScalable ? window.open(mainImageUrl, '_blank') : undefined}
            className={`page-item__main-image ${imageIsScalable ? 'page-item__main-image--big' : ''}`}
          />
          <div className="page-item__aux-images">
            {
              files.length > 1 ? files.map((file) => {
                return (
                  <SafeImage
                    key={file.id}
                    alt={fullName}
                    src={getFileUrl(file)}
                    className="page-item__aux-image"
                    onClick={() => popThisImage(file.id)}
                  />
                )
              }) : null
            }
            {
              itemCheckResult?.photos ?
                itemCheckResult.photos.map((ph, im) => {
                  return (
                    <SafeImage
                      key={im}
                      alt={fullName}
                      src={ph.thumb}
                      className="page-item__aux-image"
                      onClick={() => window.open(ph.full, '_blank')}
                    />
                  )
                })
                : null
            }
          </div>
          <div className="page-item__report">
            <a href="/#report-ad" onClick={onReportClick}>{ copies.reportAd }</a>
          </div>
        </div>

        <div>
          <h1 className="page-item__header">
            { fullName }
          </h1>
          {
            price > 0 ?
              <div className="page-item__price">
                <span>{ currencyIcon(currency) } </span>
                { formatPrice(attractivePrice(price)) }
                {
                  vatId ?
                    <span>&nbsp;<a href="/#check-vat" onClick={onVatClick}>{ copies.plusVat }</a></span>
                    : null
                }
              </div>
              : null
          }
          <hr/>

          <h2>{ copies.description }</h2>
          <pre className="page-item__description">
            { descriptionCopy.trim() }
          </pre>

          {
            aircraft.engine || aircraft.engineHours ?
              infoModule(copies.engine, [aircraft.engine, aircraft.engineHours ? `${aircraft.engineHours}h` : 0].filter(Boolean).join(', '))
              : null
          }

          { infoModule(copies.condition, [
            capitalize(getNiceState(aircraft.state, builtYear)),
            aircraft.hours ? `${aircraft.hours}h TT` : 0].filter(Boolean).join(', ')
          ) }

          {
            ([1, 2, 3, 5].includes(aircraft.accidents) || itemCheckResult?.accidents?.length) ?
              <>
                <h2>{ copies.accidents }</h2>
                <div>
                  <span>+</span>
                  {
                    (itemCheckResult?.accidents ?? []).map((accident, i) => {
                      return (
                        <span key={i}> <a href={accident.url} target="_blank" rel="noreferrer">{
                          new Intl.DateTimeFormat(languagePrefix.replace('/', ''), { year: 'numeric', month: 'long', day: 'numeric' }).format(new Date(accident.ts))
                        }</a></span>
                      )
                    })
                  }
                </div>
              </>
              :null
          }

          { equipmentList.length ? infoModule(copies.equipment, equipmentList.join(', ')) : null }

          <h2>{ copies.contact }</h2>
          <div className="page-item__contact">
            <span>{ capitalizeAll(user.name ?? '') }: </span>
            {
              phone ? <a href={`tel:${phone}`}>{phone}</a> : null
            }
            {
              phone && email ? <span>, </span> : null
            }
            {
              email ? (
                email.includes('@')
                  ? <a href={`mailto:${email}`}>{email}</a>
                  : <a href={email.startsWith('http') ? email : `https://${email}`}>{email}</a>
              ) : null
            }
          </div>

          { infoModule(copies.tailNumber, aircraft.tailNumber) }
          {
            infoModule(copies.location, safeAirfield.match(airfieldRegex)
                // TODO: link to the airfield library item later
                ? <span
                  dangerouslySetInnerHTML={{__html: safeAirfield.replace(airfieldRegex, `<a href="${languagePrefix}/airfield/$1">$1</a>`)}}
                />
                : aircraft.airfield)
          }

          {
            aircraft.type.code === 'XXX' ? null : infoModule(copies.typeInfo,
              <a target="_blank" rel="noreferrer nofollow" href={`https://doc8643.com/aircraft/${aircraft.type.code}`}>{ aircraft.type.code }</a>
            )
          }
        </div>
      </div>
      <Footer vendorToken={aircraft.vendor.name} typeToken={aircraft.type.name} />
    </div>
  )
}
