import { createRoot, Root } from 'react-dom/client'
import { ModalBottom } from './ModalBottom'
import { Modal } from './Modal'
import React from 'react'

/**
 * A helper to facilitate a simple dialog lifecycle
 */
const dialogify = async (Component: ({ onClose }: any) => JSX.Element, props: Record<string, any> = {}) => {
  let localRoot: Root

  return new Promise(resolve => {
    const
      wrapper = document.createElement('div'),
      mboxContainer = document.getElementById('root')

    if (!mboxContainer) {
      throw new Error(`Cannot find a DOM container element with ID = "root"`)
    }

    mboxContainer.appendChild(wrapper)

    const onClose = (result: any) => {
      resolve(result)

      // ease the blink in some browsers
      wrapper.style.display = 'none'
      localRoot.unmount()
    }

    localRoot = createRoot(wrapper)
    localRoot.render(<Component onClose={onClose} {...props} />)
  })
}

export { Modal, ModalBottom, dialogify }

/**
 * TODO:
 *  - support z-index switching -- keep the last active modal atop
 *  - shade out inactive modal
 */
