import React, { ReactNode, useEffect, useState } from 'react'
import { AnimatedIcon, Icon } from '../SvgIcon/paths'
import { SvgAnimation } from '../SvgAnimation'
import { getTimeToRead } from '@utils/text'
import { SvgIcon } from '../SvgIcon'
import './index.css'

export type ResultNoun = 'info' | 'warning' | 'error' | 'success'

const lookIconCodes: Record<ResultNoun, Icon> = {
  error: 'cancel',
  info: 'information',
  success: 'checkCircle',
  warning: 'alertCircle',
}

export type ToastSliceProps = {
  content: ReactNode
  title?: string
  icon?: AnimatedIcon
  look?: ResultNoun
  onClose?: () => void
  fullWidth?: boolean
}

export type ToastPosition = 'bottom-right'

export type ToastParams = {
  content: ReactNode
  title?: string
  icon?: AnimatedIcon
  position?: ToastPosition
  look?: ResultNoun
  autoClose?: boolean
}

export type ToasterProps = {
  position?: ToastPosition
}

type ManagedToast = {
  id: string
  props: ToastSliceProps
}

type ToasterChangeListener = (items: Array<ManagedToast>) => void

class ToastsManager {
  _items = [] as Array<ManagedToast>
  _changeListeners = new Set<ToasterChangeListener>()

  add (value: ManagedToast)  {
    this._items.unshift(value)
    this._changeListeners.forEach((l: ToasterChangeListener) => l(this._items))
  }
  remove (toastId: string) {
    this._items = this._items.filter(item => item.id !== toastId)
    this._changeListeners.forEach((l: ToasterChangeListener) => l(this._items))
  }
  addListener (listener: ToasterChangeListener)  {
    this._changeListeners.add(listener)
  }
  removeListener (listener: ToasterChangeListener) {
    this._changeListeners.delete(listener)
  }
}

const toastsManager = new ToastsManager()

export function ToastSlice ({content, title, icon, look, onClose, fullWidth}: ToastSliceProps): JSX.Element {
  const iconCode = icon ?? (look ? lookIconCodes[look] : '')
  const style: React.CSSProperties = {}

  if (fullWidth) {
    style.width = '100%'
  }

  return (
    <div style={style} className={`toast-slice toast-slice--${look}`}>
      {
        iconCode ?
          <div className="toast__icon">
            {
              icon === '@spinner' ?
                <SvgAnimation size={1.5} animation="spinner" />
                :
                <SvgIcon
                  size={1.3}
                  code={iconCode}
                  className={`toast__icon--${look}`}
                />
            }
          </div>
          : null
      }
      <div>
        { title ? <div className="toast__title">{ title }</div> : null }
        { content }
      </div>
      {
        onClose ?
          <div className="toast__close">
            <div onClick={onClose}>
              <SvgIcon code="close" size={1.3} />
            </div>
          </div>
        : null
      }
    </div>
  )
}

/**
 * Pop up a toast, return toast ID.
 */
export function toast (props: ToastParams): string {
  const { content, title, autoClose = true } = props
  const toastId = `toast-${Math.random()}`

  let TTL = 0

  if (typeof title === 'string') {
    TTL += getTimeToRead(title)
  }

  if (typeof content === 'string') {
    TTL += getTimeToRead(content)
  }
  toastsManager.add({
    id: toastId,
    props: {
      ...props,
      onClose: autoClose ? undefined : () => destroyToast(toastId)
    }
  })

  if (autoClose) {
    setTimeout(() => destroyToast(toastId), Math.min(15000, TTL))
  }

  return toastId
}

/**
 * Destroy a specific toast manually
 */
export function destroyToast (toastId: string): void {
  toastsManager.remove(toastId)
}

export const Toaster = (props: ToasterProps) => {
  const { position } = props
  const [visibleToasts, setVisibleToasts] = useState<Array<ManagedToast>>([])

  useEffect(() => {
    const listener = (items: Array<ManagedToast>) => {
      setVisibleToasts([...items])
    }

    toastsManager.addListener(listener)

    return () => toastsManager.removeListener(listener)
  }, [])

  return (
    <div className={`toasts-container toasts-container-${position} ${visibleToasts.length > 0 ? '' : 'toasts-container--empty'}`}>
      {
        visibleToasts.map((vt) => <ToastSlice key={vt.id} {...vt.props} />)
      }
    </div>
  )
}

Toaster.defaultProps = {
  position: 'bottom-right'
}
