import { syncModalPos, syncShadowPos, syncShadowSize, syncModalSize, ShadowData, DivRef, ModalConstraints } from './util'
import { useCallback, MouseEventHandler } from 'react'

const STICK_STEP = 10

function applyShadowDataConstraints (shadowData: ShadowData, constraints: ModalConstraints): void {
  const
    minWidth = constraints.widthRange ? constraints.widthRange[0] : 0,
    maxWidth = constraints.widthRange ? constraints.widthRange[1] : window.innerWidth,
    minHeight = constraints.heightRange ? constraints.heightRange[0] : 0,
    maxHeight = constraints.heightRange ? constraints.heightRange[1] : window.innerHeight

  shadowData.width = Math.min(maxWidth, Math.max(minWidth, shadowData.width))
  shadowData.height = Math.min(maxHeight, Math.max(minHeight, shadowData.height))
}

type UseMovableOptions = {
  isMovable: boolean
  isResizable: boolean
  isSticky: boolean
  onResize?: (sd: ShadowData) => void
  constraints?: ModalConstraints
}

type UseMovableType = {
  startAreaDrag: (arg: number) => MouseEventHandler<HTMLDivElement>
  doAreaDrag: MouseEventHandler<HTMLDivElement>
  finishAreaDrag: () => void
}

export function useMovable (modalRef: DivRef, shadowRef: DivRef, assistantRef: DivRef, shadowData: ShadowData, optional: UseMovableOptions): UseMovableType {
  const { isMovable, isResizable, isSticky, onResize, constraints } = optional

  const startAreaDrag = useCallback((mode: number) => (ev: MouseEvent) => {
    const
      modal = modalRef.current,
      shadow = shadowRef.current,
      assistant = assistantRef.current

    if (!modal || !shadow || !assistant || (!isMovable && !isResizable)) {
      // something went wrong or just cannot move
      return
    }

    if (mode > 0) {
      ev.stopPropagation()
    }

    const rect = modal.getBoundingClientRect()

    shadowData.left = rect.left
    shadowData.top = rect.top
    shadowData.width = rect.width
    shadowData.height = rect.height
    shadowData.offset = [0, 0]
    shadowData.ptStart = [ev.screenX, ev.screenY]
    shadowData.sizeStart = [rect.width, rect.height, rect.x, rect.y]
    shadowData.lastPos = [0, 0]
    shadowData.isDragged = true
    shadowData.dragMode = mode

    modal.style.userSelect = 'none'
    shadow.style.display = 'block'
    assistant.style.display = 'block'
    syncShadowSize(shadowRef, shadowData)
    syncShadowPos(shadowRef, shadowData)
  }, [isMovable, modalRef, shadowData, shadowRef, assistantRef, isResizable])

  const finishAreaDrag = useCallback(() => {
    const
      modal = modalRef.current,
      shadow = shadowRef.current,
      assistant = assistantRef.current

    shadowData.isDragged = false
    if (shadow) {
      shadow.style.display = 'none'
    }

    if (assistant) {
      assistant.style.display = 'none'
    }

    if (modal) {
      modal.classList.remove('modal--untouched')
      modal.style.userSelect = 'auto'
    }

    if (shadowData.dragMode > 0) {
      constraints && applyShadowDataConstraints(shadowData, constraints)
      onResize && onResize(shadowData)
      syncModalSize(modalRef, shadowData, constraints ?? {})
      syncModalPos(modalRef, shadowData)
    } else {
      syncModalPos(modalRef, shadowData)
    }
  }, [modalRef, shadowData, shadowRef, assistantRef, onResize, constraints])

  // @ts-ignore
  const doAreaDrag = useCallback((ev) => {
    if (!shadowData.isDragged) {
      return
    }

    if (!ev.buttons) {
      finishAreaDrag()

      return
    }

    const
      x = ev.screenX,
      y = ev.screenY,
      [x0, y0] = shadowData.ptStart,
      [lx, ly] = shadowData.lastPos

    if (Math.abs(x - lx) < STICK_STEP / 2 && Math.abs(y - ly) < STICK_STEP / 2) {
      return
    }

    shadowData.lastPos = [x, y]

    const offset: [number, number] =
      isSticky ?
        [STICK_STEP * Math.floor((ev.screenX - x0) / STICK_STEP), STICK_STEP * Math.floor((ev.screenY - y0) / STICK_STEP)] :
        [ev.screenX - x0, ev.screenY - y0]

    if (shadowData.dragMode > 0) {
      switch (shadowData.dragMode) {
        case 1:
          shadowData.top = shadowData.sizeStart[3] + offset[1]
          shadowData.height = shadowData.sizeStart[1] - offset[1]
          break

        case 2:
          shadowData.width = shadowData.sizeStart[0] - offset[0]
          shadowData.left = shadowData.sizeStart[2] + offset[0]
          break

        case 3:
          shadowData.height = shadowData.sizeStart[1] + offset[1]
          break

        case 4:
          shadowData.width = shadowData.sizeStart[0] + offset[0]
          break

        default:
      }

      syncShadowSize(shadowRef, shadowData)
    } else {
      shadowData.offset = offset
      syncShadowPos(shadowRef, shadowData)
    }
  }, [shadowData, isSticky, finishAreaDrag, shadowRef])

  return {
    // TODO: find out how to convert MouseEventHandler type to our own handler
    // @ts-ignore
    startAreaDrag,
    doAreaDrag,
    finishAreaDrag,
  }
}

