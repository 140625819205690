import { currencyIcon, formatPrice, nicerName, attractivePrice, slugify } from '@utils/text'
import React, { useEffect, useRef, useState } from 'react'
import { getDistanceToAirfield } from '@services/airfield'
import { useLocationLanguagePrefix } from '@utils/hooks'
import { Coords, RichAircraftAd } from '@serverTypes'
import { getConfig } from '@services/config'
import { SafeImage } from '../SafeImage'
import { Link } from 'react-router-dom'
import './index.css'

export type FeedItemProps = RichAircraftAd & {
  myLocation: Coords | undefined
  lazyImage: boolean
}

export const FeedItem = (props: FeedItemProps) => {
  const { files, aircraft, currency, price, id, myLocation, lazyImage } = props
  const file = files[0]
  const languagePrefix = useLocationLanguagePrefix()
  const refContainer = useRef<HTMLAnchorElement>(null)
  const [distance, setDistance] = useState<number|undefined>(undefined)

  useEffect(() => {
    const code = (aircraft.airfield || '').match(/[A-Z]{4}/)?.[0]
    if (!global.window?.ssrData?.itemFeed && myLocation && code) {
      // not SSR
      getDistanceToAirfield(myLocation, code).then(setDistance)
    }
  }, [myLocation, aircraft.airfield])

  useEffect(() => {
    if (!myLocation) {
      setDistance(undefined)
    }
  }, [myLocation])

  if (!file) {
    return null
  }

  const fullName = nicerName(aircraft.vendor.name === aircraft.type.name ? aircraft.type.name : `${aircraft.vendor.name} ${aircraft.type.name}`)

  return (
    <Link
      ref={refContainer}
      className="feed-item"
      to={`${languagePrefix}/search/${slugify(fullName)}/${id}`}
      data-airfield={(aircraft.airfield || '').match(/[A-Z]{4}/)?.[0]}
    >
      <SafeImage
        alt={fullName}
        loading={lazyImage ? 'lazy' : undefined}
        src={`${getConfig().urlPrefix}/data/images/${file.uuid}-thumb.webp`}
      />
      {
        price > 0 ?
          <div className="feed-item__price">
            <span>{ currencyIcon(currency) } </span>
            { formatPrice(attractivePrice(price)) }
          </div>
          : null
      }
      {
        distance ?
          <div className="feed-item__distance">
            { Math.round(distance) + ' km' }
          </div>
          : null
      }
      <div className="feed-item__bottom">
        <div className="feed-item__model">
          { fullName }
        </div>
      </div>
    </Link>
  )
}
