import { useExternalScript, useExternalStyle, useLocationLanguagePrefix } from '@utils/hooks'
import React, { useEffect, useRef, useState } from 'react'
import { TopNavigator } from '@components/TopNavigator'
import { AirfieldInfo } from '@components/AirfieldInfo'
import { getAirfieldByCode } from '@services/airfield'
import { SvgIcon } from '@components/UI/SvgIcon'
import { EnhancedAirfield } from '@serverTypes'
import { createRoot } from 'react-dom/client'
import { useParams } from 'react-router-dom'
import { getConfig } from '@services/config'
import { Footer } from '@components/Footer'
import { t } from 'i18next'
import './index.css'

// map docs: https://docs.mapbox.com/mapbox-gl-js/api/

export const AirfieldPage = () => {
  const params = useParams()
  const { code } = params
  const [airfieldWS, setAirfieldWS] = useState<EnhancedAirfield | undefined>(window.ssrData.getAirfieldByCode)
  const [scriptReady, setScriptReady] = useState(false)
  const refMapContainer = useRef<HTMLDivElement>(null)
  const languagePrefix = useLocationLanguagePrefix()

  useExternalScript('https://api.mapbox.com/mapbox-gl-js/v2.12.0/mapbox-gl.js', () => {
    setTimeout(() => setScriptReady(true), 500)
  })
  useExternalStyle('https://api.mapbox.com/mapbox-gl-js/v2.12.0/mapbox-gl.css')

  useEffect(() => {
    code && getAirfieldByCode(code, languagePrefix.slice(1)).then(setAirfieldWS)
  }, [code, languagePrefix])

  const airfield = airfieldWS?.airfield
  const airfieldArticle = airfieldWS?.article
  if (airfieldWS?.airfield?.name) {
    document.title = t('pages.airfield.title', {name: airfieldWS?.airfield?.name})
  }

  useEffect(() => {
    if (!scriptReady || !refMapContainer.current || !airfield) {
      return
    }

    window.mapboxgl.accessToken = getConfig().mapboxToken
    const { Map, Marker, GeolocateControl, FullscreenControl, NavigationControl } = window.mapboxgl
    const coords = airfield.coords.split(',').map(Number)

    const map = new Map({
      container: 'map',
      style: 'mapbox://styles/mapbox/streets-v12',
      center: [coords[0], coords[1]],
      zoom: 10,
    })

    const rootElement = document.createElement('div')
    const root = createRoot(rootElement)

    root.render(<SvgIcon code="airplane" color="black" size={3} />)
    new Marker(rootElement).setLngLat([coords[0], coords[1]]).addTo(map)

    map.addControl(new FullscreenControl({container: document.querySelector('body')}))
    map.addControl(new NavigationControl(), 'top-right')
    map.addControl(new GeolocateControl({
      positionOptions: { enableHighAccuracy: true },
      trackUserLocation: true,
      showUserHeading: true,
    }))
  }, [scriptReady, refMapContainer, airfield])

  if (!airfieldWS) {
    // not yet loaded
    return null
  }

  if (!airfield) {
    // offer suggestions
    return (
      <div className="page-airfield">
        <TopNavigator title={t('pages.airfield.notFound', {code})} />
        <div className="page-airfield__container">
          <h1>{ t('pages.airfield.notFound', {code}) }</h1>
          <h2>{ t('pages.airfield.notFountHint') }</h2>
          <ul className="page-airfield__suggestions">
            {
              airfieldWS.suggestions.map(s =>
                <li key={s}>
                  <a href={`${languagePrefix}/airfield/${s}`}>{ s }</a>
                </li>
              )
            }
          </ul>
        </div>
        <Footer />
      </div>
    )
  }

  return (
    <div className="page-airfield">
      <TopNavigator title={airfield.name} />
      <div className="page-airfield__container">
        <h1>{ airfield.name } ({ airfield.code })</h1>
        <div id="map" ref={refMapContainer} className="page-airfield__map" />
        <AirfieldInfo airfield={airfield} />
        {
          airfieldArticle ? <p className="page-airfield__article" dangerouslySetInnerHTML={{__html: airfieldArticle.replaceAll(/\n/g, '<br/>')}} /> : null
        }
      </div>
      <Footer />
    </div>
  )
}
