import { APIIncoming } from '@types'
import { getConfig } from './config'

export enum APICallStatusCode {
  NoError = 0,
  HttpFailed = 1,
  ServerError = 2,
  JSONError = 3,
}

export type APICallOptions = {
  get?: boolean
}

export type APIPagination = {
  start?: number
  length?: number
}

export async function apiCall<T>(endpoint: string, method?: string, payload?: any, options?: APICallOptions): Promise<{data?: T, statusCode: APICallStatusCode}> {
  let result
  let token: string | null = null
  const rootUrl = getConfig().apiUrl

  try {
    token = localStorage?.getItem('token')
  } catch (e) {
    // fukken paranoid users
  }

  try {
    if (options?.get) {
      result = await fetch(`${rootUrl}/${endpoint}?method=${method}${payload ? `&data=${JSON.stringify(payload)}` : ''}`, {
        headers: token ? {
          Token: token,
        } : {},
      })
    } else {
      result = await fetch(`${rootUrl}/${endpoint}`, {
        method: 'POST',
        body: JSON.stringify({
          method,
          data: payload,
        }),
        headers: token ? {
          Token: token,
        } : {},
      })
    }
  } catch (e: any) {
    return { statusCode: APICallStatusCode.HttpFailed } // e.message
  }

  try {
    const json: APIIncoming = await result.json()

    if (json.isError) {
      return { statusCode: APICallStatusCode.ServerError } // json.errMsg
    }

    return { data: json.data as T, statusCode: APICallStatusCode.NoError }
  } catch (e: any) {
    return { statusCode: APICallStatusCode.JSONError } // e.message
  }
}

export async function fetchFile(url: string): Promise<[boolean, string | null]> {
  const result = await fetch(url)

  if (!result.ok) {
    return [false, null]
  }

  return [true, await result.text()]
}
