import { apiCall, APICallStatusCode, APIPagination } from './api'
import { RichAircraftAd } from '@serverTypes'
import { ItemCheckResult } from '@types'

type AdsFilter = {
  token?: string
}

export interface GetItemFeedIn {
  filter?: AdsFilter
  pagination?: APIPagination
}

export interface GetItemFeedOut {
  items: Array<RichAircraftAd>
  stats: {
    total: number
  }
}

const _itemCache: Record<number, RichAircraftAd> = {}

export const getItemFeed = async ({filter, pagination}: GetItemFeedIn): Promise<GetItemFeedOut | undefined> => {
  const response = await apiCall<GetItemFeedOut>('item', 'feed', {filter, pagination})

  if (response?.data) {
    const result = response.data

    for (const item of result.items) {
      _itemCache[item.id] = item
    }

    return result
  }

  if (response?.statusCode === APICallStatusCode.HttpFailed) {
    return undefined
  }
}

export const getItemById = async (id: number): Promise<RichAircraftAd | undefined> => {
  let item: RichAircraftAd | undefined = _itemCache[id]

  if (!item) {
    item = (await apiCall<RichAircraftAd>('item', 'getById', {id}))?.data
  }

  return Promise.resolve(item)
}

export const runItemExternalChecks = async (id: number): Promise<ItemCheckResult | undefined> => {
  return (await apiCall<ItemCheckResult>('item', 'checkOutside', {id}))?.data
}
