import React from 'react'
import './index.css'

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  skin?: 'primary' | 'secondary' | 'danger'
}

export const Button = (props: ButtonProps) => {
  const { children, skin, ...rest } = props

  return (
    <button className={`button ${skin ? 'button--skin-' + skin : ''}`} {...rest}>
      { children }
    </button>
  )
}
