import { MutableRefObject } from 'react'
import { isAbove } from './dom'

export type HotKeyConfig = {
  code: string
  double?: boolean
  withShift?: boolean
  withCtrl?: boolean
  processor: (ev: KeyboardEvent) => boolean | undefined | void
}

export type HotKeyListener = {
  reference?: MutableRefObject<HTMLElement | null>
  topmostFirst?: boolean
  hotkeys: Array<HotKeyConfig>
}

const hotKeyListeners = [] as Array<HotKeyListener>

export const addHotKeyListener = (listener: HotKeyListener) => {
  hotKeyListeners.push(listener)

  // sort the listeners by their containers z-index
  // topmost go first for forEach to work best

  hotKeyListeners.sort((A: HotKeyListener, B: HotKeyListener) => {
    if (!A.reference?.current || !B.reference?.current) {
      return 0
    }

    const compared = isAbove(A.reference.current, B.reference.current)

    switch (compared) {
      case true: return -1
      case false: return +1
      default: return 0
    }
  })
}

export const removeHotKeyListener = (listener: HotKeyListener) => {
  hotKeyListeners.splice(hotKeyListeners.findIndex(x => x === listener), 1)
}

let prevCode = ''

// global keyboard listener
global.window?.addEventListener('keydown', (ev: KeyboardEvent) => {
  topLoop:
  for (const listener of hotKeyListeners) {
    for (const cfgItem of listener.hotkeys) {
      if (ev.code === cfgItem.code) {
        if ((cfgItem.double && prevCode !== ev.code) || (cfgItem.withCtrl && !ev.ctrlKey) || (cfgItem.withShift && !ev.shiftKey)) {
          // double requires prev code to be the same as the current
          continue
        }
        if (cfgItem.processor(ev) === false) {
          break topLoop
        }
      }
    }
  }
  prevCode = ev.code
  setTimeout(() => {
    prevCode = ''
  }, 350)
})
