import { EnvConfig, EnvName } from '@types'

const cfg: Record<EnvName, EnvConfig> = {
  local: {
    env: 'local',
    apiUrl: 'http://localhost:4000/api',
    urlPrefix: 'http://localhost:4000',
    eventUrl: undefined,
    // eventUrl: 'http://localhost:4000/save.png',
    mapboxToken: 'pk.eyJ1IjoidmxhZGltaXItb3NpcG92IiwiYSI6ImNsOHlsMHQ2NzBpOGMzbnF2N3NqNmRseTgifQ.KpJNEMECSBtIT4XvfJn8Yg',
    sentryDsn: undefined,
  },
  localMobile: {
    env: 'local',
    apiUrl: `http://${window.location.hostname}:4000/api`,
    urlPrefix: '',
    eventUrl: undefined,
    // eventUrl: 'http://localhost:4000/save.png',
    mapboxToken: 'pk.eyJ1IjoidmxhZGltaXItb3NpcG92IiwiYSI6ImNsOHlsMHQ2NzBpOGMzbnF2N3NqNmRseTgifQ.KpJNEMECSBtIT4XvfJn8Yg',
    sentryDsn: undefined,
  },
  stage: {
    env: 'stage',
    apiUrl: 'https://clearprop.app/api',
    urlPrefix: '',
    eventUrl: undefined,
    mapboxToken: 'pk.eyJ1IjoidmxhZGltaXItb3NpcG92IiwiYSI6ImNsOHlsMHQ2NzBpOGMzbnF2N3NqNmRseTgifQ.KpJNEMECSBtIT4XvfJn8Yg',
    sentryDsn: undefined,
  },
  prod: {
    env: 'prod',
    apiUrl: 'https://clearprop.app/api',
    urlPrefix: '',
    eventUrl: 'https://event.xroom.app/save.png',
    mapboxToken: 'pk.eyJ1IjoidmxhZGltaXItb3NpcG92IiwiYSI6ImNsOHlsMHQ2NzBpOGMzbnF2N3NqNmRseTgifQ.KpJNEMECSBtIT4XvfJn8Yg',
    sentryDsn: 'https://cd72f89a61324315902b326be52d7d4b@o4504542865326080.ingest.sentry.io/4504542866505728',
  },
}

export function getConfig(): EnvConfig {
  const host = window.location.hostname

  if (host && host.startsWith('192.')) {
    return cfg.localMobile
  }

  switch (host) {
    case 'localhost':
      return cfg.local

    default:
      return cfg.prod
  }
}

export function getLanguages () {
  return ['en', 'de', 'fr']
}
