import { EnhancedAirfield, Coords } from '@serverTypes'
import { apiCall } from '@services/api'

export const getAirfieldByCode = async (code: string, language: string): Promise<EnhancedAirfield | undefined> => {
  return (await apiCall<EnhancedAirfield>('airfield', 'getByCode', {code, withArticle: true, language}))?.data
}

let DTARequestTimer: ReturnType<typeof setTimeout>
const DTARequestMap: Record<string, (distance: number) => void> = {}

export const getDistanceToAirfield = async (from: Coords, to: string): Promise<number> => {
  return new Promise((resolve) => {
    DTARequestMap[to] = resolve
    clearTimeout(DTARequestTimer)
    DTARequestTimer = setTimeout(async () => {
      const result = (await apiCall<Record<string, number>>('airfield', 'getDistanceToEach', {from, codes: Object.keys(DTARequestMap)}))?.data

      for (const code in result) {
        DTARequestMap[code](result[code])
      }
    }, 100)
  })
}
