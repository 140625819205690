import { useCopies } from '@utils/hooks'
import React from 'react'
import './serverError.css'

export const ServerError = () => {
  const copies = useCopies<{header: string}>('pages.home.serverError')

  return (
    <div className="page-home__server-error">
      <span dangerouslySetInnerHTML={{__html: copies.header}} />
      <div className="page-home__server-error__img" />
    </div>
  )
}
