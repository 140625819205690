import React, { useCallback, useEffect, useRef } from 'react'
import { ModalConstraints, ShadowData } from './util'
import { useHotKeys } from '@utils/hooks'
import { SvgIcon } from '../../SvgIcon'
import { useMovable } from './movable'
import './index.css'

type SensitiveEdge = 'top' | 'bottom' | 'right' | 'left'

const shadowData: ShadowData = {
    left: 0,
    top: 0,
    width: 0,
    height: 0,
    offset: [0, 0],
    ptStart: [0, 0],
    sizeStart: [0, 0, 0, 0],
    lastPos: [0, 0],
    isDragged: false,
    dragMode: 0,
  }

export type ModalProps = {
  width?: string
  height?: string
  header?: React.ReactNode
  isSticky?: boolean
  isVisible?: boolean
  isMovable?: boolean
  isResizable?: boolean
  isCentered?: boolean
  isPadded?: boolean
  isStandalone?: boolean
  style?: React.CSSProperties
  useBackground?: boolean
  fsMobile?: boolean
  onClose?: () => void
  onResize?: (sd: ShadowData) => void
  sensitiveEdges?: ReadonlyArray<SensitiveEdge>
  constraints?: ModalConstraints
  children?: React.ReactNode,
  zPriority?: 1 | 2 | 3 | 4
  className?: string
}

export const Modal = (props: ModalProps): JSX.Element => {
  const {
    width, height, header, isSticky, isMovable, isResizable, isCentered, isPadded, isStandalone, zPriority,
    constraints, useBackground, style, onClose, onResize, sensitiveEdges, children, className, fsMobile
  } = props

  const
    modalRef = useRef<HTMLDivElement|null>(null),
    shadowRef = useRef<HTMLDivElement|null>(null),
    assistantRef = useRef<HTMLDivElement|null>(null)

  useEffect(() => {
    if (useBackground) {
      let backgroundWasPresent = true

      if (!document.getElementById('modal-background')) {
        const bg = document.createElement('div')
        bg.id = 'modal-background'
        document.body.appendChild(bg)
        backgroundWasPresent = false
      }

      return () => {
        const bg = document.getElementById('modal-background')

        // TODO: make decision on whether to remove bg or not based on the amount of modals-with-bg present

        !backgroundWasPresent && bg?.parentNode && bg.parentNode.removeChild(bg)
      }
    }
  }, [useBackground])

  const handleCloseClick = useCallback(() => {
    if (onClose) {
      // clicking the cross is logically a dialog cancellation
      onClose()
    }
  }, [onClose])

  const canSense = useCallback((sideName: SensitiveEdge) => !sensitiveEdges || sensitiveEdges.includes(sideName), [sensitiveEdges])

  const
    styles = {...style},
    movable = useMovable(modalRef, shadowRef, assistantRef, shadowData, {
      isMovable: Boolean(isMovable),
      isSticky: Boolean(isSticky),
      isResizable: Boolean(isResizable),
      onResize,
      constraints,
    })

  useHotKeys([{
    code: 'Escape',
    processor: () => {
      if (onClose) {
        onClose()
        return false
      }
    },
  }], modalRef)

  if (width && styles.width === undefined) {
    styles.width = width
  }
  if (height && styles.height === undefined) {
    styles.height = height
  }

  useEffect(() => {
    if (zPriority && modalRef.current) {
      // TODO: use '20' after migration is complete
      modalRef.current.style.setProperty('z-index', `${20 - zPriority}`, 'important')
    }
  }, [modalRef, zPriority])

  const insideResizable =
    <>
      <div
        onMouseDown={isMovable ? movable.startAreaDrag(0) : undefined}
        className={`modal__header ${isMovable ? 'modal__header--movable' : ''}`}
      >
        <div className="modal__header-inner">
          { header }
        </div>
        {
          onClose ?
            <div
              role="button"
              aria-label="Close modal"
              onClick={handleCloseClick}
              className="modal__close"
              onMouseDown={ev => ev.stopPropagation()}
            >
              <SvgIcon code="close"/>
            </div>
            : null
        }
      </div>
      <div className={`modal__content ${isPadded ? 'modal__content--padded' : ''}`}>
        { children }
      </div>
    </>

  const classes = ['trackable modal']

  className && classes.push(className)
  isCentered && classes.push('modal--untouched')
  isStandalone && classes.push('modal--standalone')
  fsMobile && classes.push('modal--fs-mobile')

  return (
    <>
      <div
        style={styles}
        ref={modalRef}
        className={classes.join(' ')}
      >
        {
          isResizable ?
            <div className="modal__vertical-box">
              { canSense('top') && <div className="modal__border modal__border-top" onMouseDown={movable.startAreaDrag(1)} /> }
              <div className="modal__horizontal-box">
                { canSense('left') && <div className="modal__border modal__border-left" onMouseDown={movable.startAreaDrag(2)} /> }
                <div className="modal__inside-resizable">
                  { insideResizable }
                </div>
                { canSense('right') && <div className="modal__border modal__border-right" onMouseDown={movable.startAreaDrag(4)} /> }
              </div>
              { canSense('bottom') && <div className="modal__border modal__border-bottom" onMouseDown={movable.startAreaDrag(3)} /> }
            </div>
            :
            insideResizable
        }
      </div>
      <div
        ref={assistantRef}
        onMouseMove={movable.doAreaDrag}
        onMouseUp={movable.finishAreaDrag}
        className="modal__movement-assistant"
      />
      <div
        ref={shadowRef}
        className="modal__shadow"
        onMouseMove={movable.doAreaDrag}
        onMouseUp={movable.finishAreaDrag}
      />
    </>
  )
}

Modal.defaultProps = {
  sticky: true,
  isMovable: false,
  isCentered: true,
  isPadded: true,
  isStandalone: true,
  isResizable: false,
  useBackground: true,
  sensitiveEdges: ['top', 'bottom', 'right', 'left'],
}
