import React, { ForwardedRef, forwardRef, PropsWithoutRef, ReactNode, useCallback } from 'react'
import './index.css'

type TextAreaProps = Omit<React.TextareaHTMLAttributes<HTMLTextAreaElement>, 'onChange'> & {
  input?: string
  label?: string
  onChange: (value: string) => void
  className?: string
  skin?: 'error'
  rightBlock?: ReactNode
}

export const TextArea = forwardRef<HTMLDivElement, TextAreaProps>((props: PropsWithoutRef<TextAreaProps>, outerReference: ForwardedRef<HTMLDivElement>) => {
  const { label, value, onChange, className, skin, rightBlock, ...rest } = props

  const _onChange = useCallback((ev: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange(ev.target.value)
  }, [onChange])

  return (
    <label className={['textarea', className, skin ? `input--${skin}` : ''].filter(Boolean).join(' ')}>
      {
        label ? <span>{ label }</span> : null
      }
      <div className="textarea__row" ref={outerReference}>
        <textarea
          placeholder="..."
          { ...rest }
          value={value ?? ''}
          onChange={_onChange}
        />
      </div>
    </label>
  )
})
