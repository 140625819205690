import { lockScreen } from '@components/BusyScreen'
import React, { useCallback, useRef } from 'react'
import { apiCall } from '@services/api'
import './index.css'

export const LoginPage = () => {
  const refEmail = useRef<HTMLInputElement|null>(null)
  const refPassword = useRef<HTMLInputElement|null>(null)

  const onButtonClick = useCallback(async (ev: React.MouseEvent) => {
    ev.preventDefault()

    if (refEmail.current && refPassword.current) {
      const
        identity = refEmail.current?.value.trim(),
        credential = refPassword.current?.value.trim()

      if (!identity.length || !credential.length) {
        return
      }

      lockScreen(true, {timeout: 5000})
      const data = (await apiCall<{token: string}>('auth', 'byPassword', {identity, credential}))?.data
      lockScreen(false)

      if (data && data.token) {
        localStorage.setItem('token', data.token)
        document.location.href = '/'
      }
    }
  }, [refEmail, refPassword])

  return (
    <div className="login-home">
      <form>
        <input
          autoFocus
          type="email"
          ref={refEmail}
          placeholder="your@email.address"
        />
        <input
          type="password"
          ref={refPassword}
          placeholder="password, 8+ symbols"
        />
        <button onClick={onButtonClick}>Log in</button>
      </form>
    </div>
  )
}
