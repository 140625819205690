import { useCopies } from '@utils/hooks'
import React from 'react'
import './noResults.css'

export const NoResults = () => {
  const copies = useCopies<{header: string, hint: string}>('pages.home.nothing')

  return (
    <div className="page-home__no-results">
      <span dangerouslySetInnerHTML={{__html: copies.header}} />
      <span className="page-home__no-results__hint">{ copies.hint }</span>
      <div className="page-home__no-results__img" />
    </div>
  )
}
