import React, { lazy, Suspense } from 'react'

const VFRPage = lazy(() => import('./index'))

export const VFRPageLoader = () => {
  return (
    <Suspense fallback={<div />}>
      <VFRPage />
    </Suspense>
  )
}
