/**
 * A primitive helper to detect dev mode
 */
export const isLocal = () => document.location.hostname === 'localhost'

// this is for SSR only
if (typeof window === 'undefined') {
  // @ts-ignore
  global.window = {
    // @ts-ignore
    location: {}
  }
  // @ts-ignore
  global.document = {}
  // @ts-ignore
  global.navigator = {
    userAgent: '',
  }
}

if (!window.matchMedia) {
  // @ts-ignore
  window.matchMedia = () => ({matches: true})
}

const
  isPhone = window.matchMedia('(max-width: 480px)').matches || window.matchMedia('(max-height: 480px) and (orientation: landscape)').matches,
  isTablet = window.matchMedia('(max-width: 1024px)').matches && 'ontouchend' in document


export const getQueryParam = (param: string) => {
  const value = (new URLSearchParams(window.location.search)).get(param)
  return value === '' ? 1 : value
}

export const is = {
  PHONE: isPhone,
  TABLET: isTablet,
  MOBILE: isPhone || isTablet,
  PORTRAIT: window.matchMedia('(orientation: portrait)').matches,
  IOS: ['iPad', 'iPhone', 'iPod'].includes(navigator.platform) || (navigator.userAgent.includes('Mac') && 'ontouchend' in document),
  ADMIN: getQueryParam('admin'),
}
