import { useCopies } from '@utils/hooks'
import { Airfield } from '@serverTypes'
import React from 'react'
import './index.css'

type AirfieldInfoProps = {
  airfield: Airfield
}

export const AirfieldInfo = (props: AirfieldInfoProps) => {
  const a = props.airfield
  const copies = useCopies<Record<string, string>>('components.airfieldInfo')
  const coords = a.coords.split(',')
  const weatherLink = `https://www.windy.com/${a.code}?${coords[1].trim()},${coords[0]}`

  return (
    <div className="airfield-info">
      <table>
        <tbody>
        <tr><td>{ copies.code }</td><td>{ a.code }</td></tr>
        <tr><td>{ copies.name }</td><td>{ a.name }</td></tr>
        <tr><td>{ copies.country }</td><td>{ a.country }</td></tr>
        <tr><td>{ copies.city }</td><td>{ a.city }</td></tr>
        <tr><td>{ copies.elevation }</td><td>{ a.elevation || 0 }ft</td></tr>
        <tr>
          <td>{ copies.weather }
          </td>
          <td>
            <a
              target="_blank"
              rel="nofollow noreferrer"
              href={weatherLink}
            >
              { weatherLink }
            </a>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  )
}
