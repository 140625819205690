// https://developers.google.com/search/docs/appearance/structured-data/product#json-ld

import React from 'react'

type JSONLDProps = {
  id: number
  name: string
  price: number
  currency: string
  images: Array<string>
  description: string
  brandName: string
  isNew?: boolean
  userName: string
}

export const JSONLD = (props: JSONLDProps) => {
  const { id, name, price, images, description, brandName, currency, isNew, userName } = props

  const thisYear = (new Date()).getFullYear()
  const inAYear = (new Date()).toISOString().split('T')[0].replace(String(thisYear), String(thisYear + 1))

  const data = {
    '@context': 'https://schema.org/',
    '@type': 'Product',
    sku: `clearprop-${id}`,
    name,
    image: images,
    description,
    price,
    brand: {
      '@type': 'Brand',
      name: brandName,
    },
    offers: {
      '@type': 'Offer',
      price: price,
      priceCurrency: currency,
      itemCondition: isNew ? 'https://schema.org/NewCondition' : 'https://schema.org/UsedCondition',
      availability: 'https://schema.org/InStock',
      priceValidUntil: inAYear,
    },
    review: {
      '@type': 'Review',
      reviewRating: {
        '@type': 'Rating',
        ratingValue: 5,
        bestRating: 5,
      },
      author: {
        '@type': 'Person',
        name: userName,
      },
    },
    aggregateRating: {
      '@type': 'AggregateRating',
      ratingValue: 5,
      reviewCount: 1,
    },
  }

  return React.createElement('script', {
    type: 'application/ld+json',
    dangerouslySetInnerHTML: { __html: JSON.stringify(data) }
  })
}
