import { useLocationLanguagePrefix } from '@utils/hooks'
import React, { MouseEvent, useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import { NavButton } from '../NavButton'
import { is } from '@utils/platform'
import './index.css'

type TopNavigatorProps = {
  title: string
}

export const TopNavigator = (props: TopNavigatorProps) => {
  const { title } = props
  const location = useLocation()
  const languagePrefix = useLocationLanguagePrefix(true)

  const onShareClick = useCallback((ev: MouseEvent<HTMLAnchorElement>) => {
    ev.preventDefault()
    navigator.share({
      title,
      url: location.pathname,
    })
  }, [location, title])

  return (
    <div className="top-navigator">
      <NavButton to={languagePrefix} icon="home" />
      {
        is.MOBILE && typeof navigator.share === 'function' ?
          <NavButton
            icon="shareVariant"
            onClick={onShareClick}
            to={`${location.pathname}#share`}
          />
          : null
      }
    </div>
  )
}
