export const ColorCodes = {
  transparent: 'transparent',
  white: 'white',
  primary: 'var(--color-primary)',
  primaryDark: 'var(--color-primary-dark)',
  secondary: 'orange',
  black: '#111',

  // this is for debugging only
  unknown: 'red',
}

export type Color = keyof typeof ColorCodes

export function getColorCode (name: Color): string {
  return ColorCodes[name]
}
