import { SvgAnimation } from '@components/UI/SvgAnimation'
import React, { useEffect, useState } from 'react'
import './index.css'

let wdTimer: NodeJS.Timeout

type LockScreenOptions = {
  timeout?: number
}

export const lockScreen = (flag: boolean, options: LockScreenOptions = {}) => {
  const { timeout } = options

  if (!flag) {
    clearTimeout(wdTimer)
  }
  document.dispatchEvent(new CustomEvent('lock-screen', { detail: flag }))

  if (timeout) {
    wdTimer = setTimeout(() => {
      document.dispatchEvent(new CustomEvent('lock-screen', { detail: false}))
    }, timeout)
  }
}

export const BusyScreen = () => {
  const [busy, setBusy] = useState(false)

  useEffect(() => {
    const listener = (ev: Event) => {
      setBusy((ev as CustomEvent).detail )
    }

    document.addEventListener('lock-screen', listener)

    return () => document.removeEventListener('lock-screen', listener)
  }, [])

  if (!busy) {
    return null
  }

  return (
    <div className="busy-screen">
      <SvgAnimation color="secondary" size={4} />
    </div>
  )
}
