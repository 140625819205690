import { AircraftState, Equipment } from '@serverTypes'
import { t } from 'i18next'

export const listEquipment = (e: Equipment): Array<string> => {
  let items: Array<string> = []
  const keys = Object.keys(e)
  const copies: Record<string, string> = t('equipment', { returnObjects: true })

  for (const key of keys) {
    // @ts-ignore
    if (e[key]) {
      items.push(copies[key])
    }
  }

  return items
}

export const getNiceState = (s: AircraftState, year?: string): string => {
  const copies: Record<string, string> = t('conditions', { returnObjects: true })

  switch (s) {
    case AircraftState.New: return copies.new
    case AircraftState.NeedsRepair: return copies.needsRepair
    default:
      if (year) {
        return copies.usedYear.replace('{{year}}', year)
      }
      return copies.used
  }
}
