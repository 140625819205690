export type DivRef = import('react').RefObject<HTMLDivElement | undefined>

export type ShadowData = {
  left: number
  top: number
  width: number
  height: number
  offset: [number, number]
  ptStart: [number, number]
  sizeStart: [number, number, number, number]
  lastPos: [number, number]
  isDragged: boolean
  dragMode: number
}

export type ModalConstraints = {
  fixedWidth?: string
  fixedHeight?: string
  widthRange?: [number, number]
  heightRange?: [number, number]
}

export function syncShadowSize (ref: DivRef, data: ShadowData) {
  const s = ref?.current?.style

  if (s) {
    s.left = `${data.left}px`
    s.top = `${data.top}px`
    s.width = `${data.width}px`
    s.height = `${data.height}px`
  }
}

export function syncShadowPos (ref: DivRef, data: ShadowData) {
  const s = ref?.current?.style

  if (s) {
    s.transform = `translate(${data.offset[0]}px, ${data.offset[1]}px)`
  }
}

export function syncModalPos (ref: DivRef, data: ShadowData) {
  const s = ref?.current?.style

  if (s) {
    s.left = `${data.left + data.offset[0]}px`
    s.top = `${data.top + data.offset[1]}px`
  }
}

export function syncModalSize (ref: DivRef, data: ShadowData, constraints: ModalConstraints) {
  const s = ref?.current?.style

  if (s) {
    s.width = constraints.fixedWidth ?? `${data.width + data.offset[0]}px`
    s.height = constraints.fixedHeight ?? `${data.height + data.offset[1]}px`
  }
}
