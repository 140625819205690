import { MutableRefObject, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { addHotKeyListener, HotKeyConfig, removeHotKeyListener } from '@utils/keyboard'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

/**
 * A hook to minimize code size when working with translatable copies
 */
export const useCopies = <T>(key: string): T => {
  const { t } = useTranslation()

  // @ts-ignore
  return t(key, { returnObjects: true })
}

/**
 * TODO: description
 */
export const useLocationLanguagePrefix = (short?: boolean) => {
  const location = useLocation()
  const segments = location.pathname.split('/')

  if (segments.length > 1 && segments[1].length === 2) {
    const lng = segments[1].toLowerCase()

    if (['de', 'fr'].includes(lng)) {
      return `/${lng}`
    }
  }

  return short ? '/' : '/en'
}

export const useRandom = () => {
  // eslint-disable-next-line
  const [_, setRandom] = useState(0)

  return  useCallback((x: any) => {
    setRandom(Math.random)
    return x
  }, [])
}

export type UseOneUnderAnotherOptions = {
  deps?: Array<any>
  align?: 'left' | 'right'
  syncWidth?: boolean
  follow?: boolean
}

/**
 * Positions one element under another
 */
export function useOneUnderAnother(topRef: MutableRefObject<HTMLElement | null>, bottomRef: MutableRefObject<HTMLElement | null>, options?: UseOneUnderAnotherOptions) {
  const deps = options?.deps ?? []
  const align = options?.align ?? 'left'
  const syncWidth = options?.syncWidth ?? false
  const follow = options?.follow ?? false
  const refreshTimer = useRef<ReturnType<typeof setTimeout> | undefined>()

  const recompute = useCallback((topObj: HTMLElement, bottomObj: HTMLElement) => {
    const tRect = topObj.getBoundingClientRect()
    let bRect = bottomObj.getBoundingClientRect()

    // figure out where we have space
    const
      hasSpaceAbove = tRect.top - bRect.height > 0,
      hasSpaceBelow = tRect.top + tRect.height + bRect.height < window.innerHeight

    bottomObj.style.visibility = 'visible'
    for (const child of bottomObj?.children) {
      child.classList.remove('hidden')
    }

    const zeroLevel =
      hasSpaceBelow || !hasSpaceAbove ? tRect.top + tRect.height
        : tRect.top - bRect.height - 8

    bottomObj.style.top = `${zeroLevel}px`

    if (syncWidth) {
      bottomObj.style.width = `${tRect.width}px`
    }

    if (align === 'right') {
      bottomObj.style.right = `${window.innerWidth - tRect.right}px`
    }

    if (align === 'left') {
      bottomObj.style.left = `${tRect.left}px`
    }

    // after we do the initial positioning we need to re-evaluate
    // whether the bottom container fits the screen properly
    bRect = bottomObj.getBoundingClientRect()

    const
      hasSpaceAleft = bRect.left > 0,
      hasSpaceAright = bRect.left + bRect.width < window.innerWidth,
      hasSpaceBelow2 = bRect.top + tRect.height + bRect.height < window.innerHeight

    if (!hasSpaceAright) {
      bottomObj.style.left = `${window.innerWidth - bRect.width}px`
    } else if (!hasSpaceAleft) {
      bottomObj.style.left = '0px'
    }

    if (!hasSpaceBelow2) {
      bottomObj.style.top = `${tRect.top - bRect.height - 8}px`
    }
  }, [align, syncWidth])

  useLayoutEffect(() => {
    if (topRef.current && bottomRef.current) {
      if (bottomRef.current && bottomRef.current?.style.visibility !== 'visible') {
        bottomRef.current.style.visibility = 'hidden'
        for (const child of bottomRef.current?.children) {
          child.classList.add('hidden')
        }
      }

      // initial render
      setTimeout(recompute, 50, topRef.current, bottomRef.current)

      if (follow) {
        // @ts-ignore
        refreshTimer.current = setInterval(recompute, 500, topRef.current, bottomRef.current)
      }
    } else {
      // stop periodic recomputing
      clearInterval(refreshTimer.current)
    }

    return () => {
      clearInterval(refreshTimer.current)
    }

    // eslint-disable-next-line
  }, [topRef.current, bottomRef.current, align, ...deps])
}

export function useExternalScript (url: string, onReady?: () => void, condition?: () => boolean) {
  useEffect(() => {
    if (condition && !condition()) {
      return
    }

    const head = document.querySelector('head') as HTMLHeadElement
    const script = document.createElement('script')

    script.setAttribute('src', url)
    if (onReady) {
      script.addEventListener('load', onReady)
    }

    head.appendChild(script)

    return () => {
      head.removeChild(script)
    }
  }, [url, onReady, condition])
}

export function useExternalStyle (url: string, condition?: () => boolean) {
  useEffect(() => {
    if (condition && !condition()) {
      return
    }

    const head = document.querySelector('head') as HTMLHeadElement
    const script = document.createElement('link')

    script.setAttribute('href', url)
    script.setAttribute('rel', 'stylesheet')
    head.appendChild(script)

    return () => {
      head.removeChild(script)
    }
  }, [url, condition])
}

/**
 * Hotkey helper hook
 */
export function useHotKeys (hotkeys: Array<HotKeyConfig>, reference?: MutableRefObject<HTMLElement | null>, _deps?: any[]): void {
  const deps = _deps || []
  const listener = useMemo(() => ({
    hotkeys,
    reference,
    topmostFirst: true,
    // eslint-disable-next-line
  }), [hotkeys, reference, ...deps])

  useEffect(() => {
    addHotKeyListener(listener)

    return () => {
      removeHotKeyListener(listener)
    }
    // eslint-disable-next-line
  }, [listener, ...deps])
}

export function useAsyncEffect (effect: () => Promise<void>, deps: any[]) {
  useEffect(() => {
    (async function () {
      void effect()
    })()
    // eslint-disable-next-line
  }, deps)
}

export function useRender () {
  const [, setState] = useState(false)
  return () => setState(v => !v)
}
