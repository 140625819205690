import { Icon } from '@components/UI/SvgIcon/paths'
import { Link, LinkProps } from 'react-router-dom'
import { SvgIcon } from '@components/UI/SvgIcon'
import React from 'react'
import './index.css'

type NavButtonProps = LinkProps & React.RefAttributes<HTMLAnchorElement> & {
  icon: Icon
  tinted?: boolean
}

export const NavButton = (props: NavButtonProps) => {
  const { icon, tinted, className, ...rest } = props

  const classes = [tinted ? 'nav-button--tinted' : undefined]
  className && classes.push(className)

  return (
    <Link className="nav-button" {...rest}>
      <SvgIcon code={icon} className={classes.join(' ')} />
    </Link>
  )
}
