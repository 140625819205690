import { BusyScreen } from '@components/BusyScreen'
import { Toaster } from '@components/UI/Toaster'
import { BrowserRouter } from 'react-router-dom'
import { BrowserTracing } from '@sentry/tracing'
import reportWebVitals from './reportWebVitals'
import { getConfig } from '@services/config'
import * as Sentry from '@sentry/react'
import ReactDOM from 'react-dom/client'
import React from 'react'
import App from './App'
import './i18n/config'
import './index.css'

declare global {
  interface Window {
    ssrData: any
  }
}

window.ssrData = {}   // for browser environment

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

// wrap with <React.StrictMode> for testing from time to time

const sentryDsn = getConfig().sentryDsn

if (sentryDsn) {
  Sentry.init({
    dsn: sentryDsn,
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  })
}

root.render(
  <>
    <BusyScreen />
    <Toaster />
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log)
