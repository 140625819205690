import { Feature, Point } from 'geojson'
import { Coords } from '@serverTypes'

type FrontendSettings = {
  ui: {
    useTracing: boolean
  }
}

type PersistentData = {
  camera: [number, number, number]
  settings: FrontendSettings
  routePoints: Feature<Point>[]
  userPoints: Feature<Point>[]
}

type VolatileData = {
  jwk: string | undefined
  coords: Coords | undefined
}

type StorageData = PersistentData & VolatileData

const persistenceMap = {
  camera: true,
  settings: true,
  routePoints: true,
  userPoints: true,

  jwk: false,
}

const defaultValues = {
  camera: [23.881138, 54.879906, 9],
  settings: {
    ui: {
      useTracing: false,
    }
  },
  routePoints: [],
  userPoints: [],

  jwk: undefined,
  coords: undefined,
} as StorageData

// TODO: report errors somewhere

export const storage = {
  _data: {} as StorageData,

  /**
   * Run this at start to init the storage
   */
  async init () {
    let json = {}

    try {
      json = JSON.parse(localStorage.getItem('@persistent') ?? '{}')
    } catch (e) {
      // read error
    }

    // merge with the default values
    this._data = Object.assign(defaultValues, json)
  },

  /**
   * Fetch helper, note there's no async
   */
  get<T extends keyof StorageData>(key: T): StorageData[T] {
    return this._data[key]
  },

  dump(): string {
    return JSON.stringify(this._data)
  },

  /**
   * Save helper
   */
  async set (key: keyof StorageData, value: any) {
    // @ts-ignore
    this._data[key] = value

    if (key in persistenceMap) {
      const json = JSON.stringify(this._data)

      try {
        localStorage.setItem('@persistent', json)
      } catch (e) {
        // read error
      }
    }
  },

  /**
   * Do a full reset
   */
  async reset () {
    this._data = defaultValues

    localStorage.clear()
  },
}
