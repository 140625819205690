/**
 * Turns a camelCased string into a dash-cased
 */
export function camelToDashCase (str: string): string {
  return str.replace(/[A-Z]/g, letter => `-${letter.toLowerCase()}`)
}

/**
 * Turns ay text into a dash-lowercase string
 */
export function slugify (str: string): string {
  return str.toLowerCase().replace(/\s+/g, '-').replace(/[^a-z0-9-]/gi, '')
}

/**
 * Trailing zero for time-related numbers
 */
export function nums (num: number): string {
  return String(num > 9 ? num : '0' + num)
}

/**
 * A function to calculate how much time in ms an average human needs to read a text
 */
export function getTimeToRead (str: string): number {
  let
    wordLength = 5,
    bonusTime = 500,
    wordsPerMinute = 180

  return Math.round((str.length / wordLength / wordsPerMinute) * 60000 + bonusTime)
}

/**
 * Capitalize a word
 */
export function capitalize (word: string): string {
  return word[0].toUpperCase() + word.slice(1).toLowerCase()
}

/**
 * Capitalize all words
 */
export const capitalizeAll = (str: string): string => {
  const splitStr = str.toLowerCase().split(' ')

  for (let i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
  }

  return splitStr.join(' ')
}

/**
 * Test if a given string is a URL
 */
export function isValidURL (urlString: string): boolean {
  const urlPattern = new RegExp('^(https?:\\/\\/)?' + // validate protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +      // validate domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' +                           // validate OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +                       // validate port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' +                              // validate query string
    '(\\#[-a-z\\d_]*)?$','i')                           // validate fragment locator

  return urlPattern.test(urlString)
}

/**
 * TODO: description
 */
export function nicerName (str: string): string {
  return (str ?? '').split(' ').map(word => {
    if (word.toLowerCase().replace(/[^a-z]/g, '').length <= 2) {
      return word.toUpperCase()
    }

    return word
  }).join(' ')
}

/**
 * TODO: description
 */
export function currencyIcon (currency: string): string {
  switch (currency.toUpperCase()) {
    case 'USD': return '$'
    case 'EUR': return '€'
    case 'GBP': return '£'
    case 'CHF': return 'CHF'
    case 'DKK': return 'kr'
    case 'SEK': return 'kr'
    case 'NOK': return 'kr'
    case 'ISK': return 'kr'
    case 'CZK': return 'Kč'
    case 'HUF': return 'Ft'
    case 'PLN': return 'zł'
    case 'RON': return 'lei'
    case 'BGN': return 'лв'
    case 'HRK': return 'kn'
    case 'RSD': return 'din.'
    case 'MEK': return 'din.'
    case 'BAM': return 'KM'
    case 'GEL': return '₾'
    case 'MDL': return 'lei'
    case 'UAH': return '₴'
    case 'BYN': return 'Br'

    default: return ''
  }
}

/**
 * TODO: description
 */
export function formatPrice (price: number): string {
  return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}

/**
 * TODO: description
 */
export function attractivePrice (price: number): number {
  if (price <= 0) {
    return 0
  }

  if (price % 1000 > 500) {
    if (price % 50 === 0) {
      return price - 1
    }
  }

  if (price % 100 === 0) {
    return price - 1
  }

  return price
}

export const safeJSONParse = (code: string): any => {
  try {
    return JSON.parse(code)
  } catch (e: any) {
    return code
  }
}
