import { Modal, ModalBottom } from '@components/UI/Modal'
import { Button } from '@components/UI/Button'
import { capitalizeAll } from '@utils/text'
import { useCopies } from '@utils/hooks'
import { VATObject } from '@serverTypes'
import React from 'react'

export type VatFormProps = {
  onClose: (a: any) => void
  vatId: string
  report: VATObject
}

export const VatForm = ({onClose, report, vatId}: VatFormProps) => {
  const copies = useCopies<{header: string, isValid: string, address: string, company: string, countryCode: string, vatId: string}>('modals.vatForm')
  const buttons = useCopies<{yes: string, no: string, ok: string}>('buttons')

  return (
    <Modal header={copies.header} className="form-vat">
      <table>
        <tr><td>{ copies.vatId }</td><td>{ vatId }</td></tr>
        <tr><td>{ copies.isValid }</td><td>{ report.valid ? buttons.yes : buttons.no }</td></tr>
        <tr><td>{ copies.company }</td><td>{ report.company }</td></tr>
        <tr><td>{ copies.countryCode }</td><td>{ report.countryCode }</td></tr>
        <tr><td>{ copies.address }</td><td>{ capitalizeAll(report.address) }</td></tr>
      </table>
      <ModalBottom>
        <Button onClick={onClose}>{ buttons.ok }</Button>
      </ModalBottom>
    </Modal>
  )
}
