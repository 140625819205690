import { Modal, ModalBottom } from '@components/UI/Modal'
import { TextArea } from '@components/UI/TextArea'
import { Button } from '@components/UI/Button'
import { Input } from '@components/UI/Input'
import { getCopies } from '@services/system'
import { Gap } from '@components/UI/Gap'
import { useCopies } from '@utils/hooks'
import React, { useState } from 'react'

export type FeedbackFormProps = {
  onClose: (arg: false | {email: string, feedback: string}) => void
}

export const FeedbackForm = ({onClose}: FeedbackFormProps) => {
  const [email, setEmail] = useState('')
  const [feedback, setFeedback] = useState('')
  const copies = useCopies<{header: string, email: string, comment: string}>('modals.adFeedback')
  const ok = email.trim().length && feedback.trim().length

  return (
    <Modal header={copies.header} className="form-feedback" onClose={() => onClose(false)}>
      <Input value={email} onChange={setEmail} label={copies.email} placeholder="john.doe@mail.com" autoFocus />
      <Gap />
      <TextArea value={feedback} onChange={setFeedback} label={copies.comment} placeholder="" rows={5} />
      <ModalBottom>
        <Button
          onClick={() => ok && onClose({email, feedback})}
          disabled={!ok}
        >
          { getCopies('buttons.send') }
        </Button>
      </ModalBottom>
    </Modal>
  )
}
